import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FirstHorAdBanner from '../ad-components/1st-ver-ad-banner';
import LanguageSwitcher from './language';
import { useTranslation } from 'react-i18next';
import { routePaths } from '../../const/variable.const';

const Navbar = () => {

	const handleClick = (event) => {
		// event.preventDefault()
		// Thực hiện hành động khác mà bạn muốn
	};

	const { t } = useTranslation('common');

	let publicUrl = process.env.PUBLIC_URL + '/'

	return (
		<div className="navbar-area">
			<div className="adbar-area d-none d-lg-block">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 col-lg-6 align-self-center">
							<div className="logo text-md-left text-center row"
								style={{
									display: "flex",
									alignItems: "center"
								}}
							>
								<div className="col-xl-2 col-lg-2">
									<Link className="main-logo" to="/"><img className="logo-img" src={publicUrl + "logo-s.jpg"} alt="img"
										style={{
											width: "6vw"
										}}
									/>
									</Link>
								</div>
								<div className="col-xl-10 col-lg-14">
									<div className='nav-title'>
										<h2>{t('systemName')}</h2>
									</div>
									<div className='nav-sub-title'>
										<p><b>{t('manageBy')}</b></p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-7 col-lg-5 text-md-right text-center">
							<FirstHorAdBanner height="10vw" />
						</div>
					</div>
				</div>
			</div>
			{/* adbar end*/}
			{/* navbar start */}
			<nav className="navbar navbar-expand-lg">
				<div className="container nav-container">
					<div className="responsive-mobile-menu">
						<div className="logo d-lg-none d-block logo-title"
						>
							<div className="col-md-3 col-sm-3 col-2">
								<Link className="main-logo" to="/"><img className="logo-img" src={publicUrl + "logo-s.jpg"} alt="img"
									style={{
										width: "10vw"
									}}
								/>
								</Link>
							</div>
							<div className="col-md-14 col-sm-12 col-18">
								<div className='nav-title'>
									<h5>{t('systemName')}</h5>
								</div>
								<div className='nav-sub-title'>
									<p><b>{t('manageBy')}</b></p>
								</div>
							</div>
						</div>
						<button className="menu toggle-btn d-block d-lg-none"
							data-toggle="collapse"
							data-target="#nextpage_main_menu" aria-expanded="false" aria-label="Toggle navigation">
							<span className="icon-left" />
							<span className="icon-right" />
						</button>
					</div>

					<div className="collapse navbar-collapse" id="nextpage_main_menu">
						<LanguageSwitcher />
						<ul className="navbar-nav menu-open">
							<li className="menu-item-has-children current-menu-item">
								<Link to="/" onClick={handleClick}>{t('navbar.homepage')}</Link>
							</li>
							<li className="menu-item-has-children current-menu-item">
								<Link to={routePaths.personalTaxAudit.fe} onClick={handleClick}>{t('navbar.personalIncomeTax')}</Link>
								{/* <ul className="sub-menu">
									<li><Link to={routePaths.personalTaxAudit.fe}>{t('navbar.personal')}</Link></li>
								</ul> */}
							</li>
							<li className="menu-item-has-children current-menu-item">
								<Link to={routePaths.retirementPlan.fe} onClick={handleClick}>{t('navbar.retirementPlan')}</Link>
							</li>
							<li className="menu-item-has-children current-menu-item">
								<Link to={routePaths.aboutUs} onClick={handleClick}>{t('navbar.aboutUs')}</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>

	)
}


export default Navbar