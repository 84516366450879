import React from 'react';
import { useTranslation } from 'react-i18next';
import landingBg from "../assets/landing/landing-bg.jpg";
import aboardImg from "../assets/landing/aboard-sticker.png";
import localVietnameseImg from "../assets/landing/local-sticker.png"
import guidelineImg from "../assets/landing/guideline-sticker.png"
import SidebarBackgroundImage from '../components/global-components/sidebar-bg-img';

const LandingPage = () => {
    const { t } = useTranslation('landing');
    const featureData = t('features', { returnObjects: true });
    const instructionData = t('instructionForUse', { returnObjects: true });

    const aboardList = Array.isArray(featureData?.aboard?.descriptions) ? featureData.aboard.descriptions : [];

    const vietnameseList = Array.isArray(featureData?.vietnamese?.descriptions) ? featureData.vietnamese.descriptions : [];

    const guidelineList = Array.isArray(featureData?.guideline?.descriptions) ? featureData.guideline.descriptions : [];


    const instructionForUseList = Array.isArray(instructionData?.list) ? instructionData.list : [];


    return (
        <div>
            <div className='row landing-bg-frame'>
                <div className='thumb after-left-top image-container'>
                    <img src={landingBg} alt="img" />
                </div>
            </div>
            <div className="row">
                <SidebarBackgroundImage img="L" />
                <div className="col-xl-8 col-lg-6 pd-top-50">
                    <div className='row'>
                        <div className='container'>
                            <div className="row welcome-space">
                                <h3><b>{t('welcome.title')}</b></h3>
                                <h6>{t('welcome.text')}</h6>
                            </div>
                            <div className="row features-space">
                                <h3><b>{t('features.title')}</b></h3>
                                <div className="feature-list">
                                    <div className="feature-item">
                                        <h6>{t('features.aboard.title')}</h6>
                                        <div className="single-post-list-wrap">
                                            <div className="media">
                                                <div className="media-left">
                                                    <img src={aboardImg} alt="img" />
                                                </div>
                                                <div className="media-body">
                                                    <div className="details">
                                                        <ul>
                                                            {aboardList.map((text, index) => (
                                                                < li key={index} ><h6>{text}</h6></li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="feature-list">
                                    <div className="feature-item">
                                        <h6>{t('features.vietnamese.title')}</h6>
                                        <div className="single-post-list-wrap">
                                            <div className="media">
                                                <div className="media-left">
                                                    <img src={localVietnameseImg} alt="img" />
                                                </div>
                                                <div className="media-body">
                                                    <div className="details">
                                                        <ul>
                                                            {vietnameseList.map((text, index) => (
                                                                < li key={index} ><h6>{text}</h6></li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="feature-list">
                                    <div className="feature-item">
                                        <h6>{t('features.aboard.title')}</h6>
                                        <div className="single-post-list-wrap">
                                            <div className="media">
                                                <div className="media-left">
                                                    <img src={guidelineImg} alt="img" />
                                                </div>
                                                <div className="media-body">
                                                    <div className="details">
                                                        <ul>
                                                            {guidelineList.map((text, index) => (
                                                                < li key={index} ><h6>{text}</h6></li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row instruction-space ">
                                <h3><b>{t('instructionForUse.title')}</b></h3>
                                <div className='feature-list'>
                                    <h6>{t('instructionForUse.text')}</h6>
                                    <ul>
                                        {instructionForUseList.map((text, index) => (
                                            < li key={index} ><h6>{text}</h6></li>
                                        ))}
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <SidebarBackgroundImage img="R" />
            </div>
        </div >

    );
}

export default LandingPage