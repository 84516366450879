import React, { } from 'react';
import Sidebar from '../../global-components/sidebar';
import TaxAuditTableDetails from './tax-audit-table';
import PersonalTaxAuditFillForms from './tax-audit-personal-fill-forms';
import { useTranslation } from 'react-i18next';

const PersonalTaxAuditDetails = () => {
    const { t } = useTranslation('personalTaxAudit');
    return (
        <div className="blog-page-area pd-bottom-80 go-top">
            <div className="container">
                <div className="row">
                    <Sidebar />
                    <div className="col-xl-8 col-lg-6 pd-top-50">
                        <div className='row'>
                            <div className="col-lg-24 col-sm-8 col-20"
                            >
                                <div className="container">
                                    <div className="card tax-audit">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#tax-audit-table-details" aria-expanded="true" aria-controls="tax-audit-table-details">{t('taxAudit.personal.progressiveTaxRatioForPersonalIncome.tableTitle')}
                                                    &nbsp;
                                                    <i className="fa fa-angle-down" />
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="tax-audit-table-details" className="collapse" aria-labelledby="headingOne">
                                            <div className="card-body">
                                                <TaxAuditTableDetails />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-24 col-sm-8 col-20 container">
                                <br /><br />
                                <PersonalTaxAuditFillForms />
                            </div>
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </div>

        </div>

    )

}

export default PersonalTaxAuditDetails;
