import React, { } from 'react';
import FormFill from './form-fill';
import { useTranslation } from 'react-i18next';

const PersonalTaxAuditFillForms = () => {
    const { t } = useTranslation('personalTaxAudit');
    // Lấy dữ liệu từ dịch vụ với returnObjects để xử lý object và array
    const noteList = t('taxAudit.personal.formFill.note.list', { returnObjects: true });

    return (
        <div className="personal-tax-audit-fill-forms">
            <div className="fill-forms-header">
                <h3>{t('taxAudit.personal.formFill.header')}</h3>
                <p>
                    {t('taxAudit.personal.formFill.desciption')}
                </p>
            </div>
            <div className="fill-forms-notes" style={{ marginLeft: "3vw" }}>
                <h5> {t('taxAudit.personal.formFill.note.text')}</h5>
                <ul>
                    {noteList.map((note, index) => (
                        ((noteList.length - 1) === index) ?
                            (<li key={index}><b>{note}</b></li>) : (<li key={index}>{note}</li>)
                    ))}
                </ul>
            </div>
            <div className='form-year'>
                <FormFill />
            </div>
        </div>

    )
}

export default PersonalTaxAuditFillForms;