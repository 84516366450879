import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import axiosInstance from './http-common-api';
import { routePaths, namespaces } from './const/variable.const';

// Function to load translations
const loadTranslations = async (language, ns) => {
    // Kiểm tra cache và thời gian hết hạn
    const cacheKey = `${language}_${ns}`;
    const cachedTranslations = localStorage.getItem(cacheKey);
    const cacheExpiryKey = `${cacheKey}_expiry`;
    const cacheExpiry = localStorage.getItem(cacheExpiryKey);

    const isCacheValid = cacheExpiry && new Date().getTime() < cacheExpiry;

    if (cachedTranslations && isCacheValid) {
        // console.log("Using cached translations");
        return JSON.parse(cachedTranslations);
    }

    try {
        // console.log("language: ", language)
        const response = await axiosInstance.get(`${routePaths.apiUrl}${routePaths.translation}?filters[language][$eq]=${language}&fields[0]=${ns}`);
        const translations = response.data.data[0]?.attributes?.[ns] || {};
        // console.log("Translations loaded", translations);

        // Lưu cache và đặt thời gian hết hạn (ví dụ 1 giờ)
        localStorage.setItem(cacheKey, JSON.stringify(translations));
        const expiryTime = new Date().getTime() + 1800 * 1000;
        // const expiryTime = new Date().getTime() + 3600 * 1000; // 1 giờ (3600 giây)
        localStorage.setItem(cacheExpiryKey, expiryTime);

        return translations;
    } catch (error) {
        console.error('Error fetching translations', error);
        return {};
    }
};

// Initialize i18n
i18n
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem('selectedLanguage') || 'vi', // Default language
        fallbackLng: ['en', 'vi'], // Fallback languages
        defaultNS: 'common', // Default namespace
        ns: namespaces, // List of namespaces
        interpolation: {
            escapeValue: false, // React handles escaping
        },
        backend: false, // Disable backend loading
        debug: false, // Disable debug for production
        initImmediate: false, // Prevents i18n from initializing immediately
    });

// Function to manually load namespace translations for a specific language
const loadNamespaceForLanguage = async (lng, namespaces) => {
    if (!Array.isArray(namespaces)) namespaces = [namespaces];

    for (const ns of namespaces) {
        const translations = await loadTranslations(lng, ns);
        i18n.addResourceBundle(lng, ns, translations, true, true);
    }
};

// const clearTranslationCache = (language, ns) => {
//     const cacheKey = `${language}_${ns}`;
//     const cacheExpiryKey = `${cacheKey}_expiry`;
//     localStorage.removeItem(cacheKey);
//     localStorage.removeItem(cacheExpiryKey);
//     console.log("Cache cleared for", cacheKey);
// };

// // Load translations for default language and namespaces
// (async () => {
//     await loadNamespaceForLanguage('vi', namespaces); // Load 'vi' with all necessary namespaces by default
//     i18n.changeLanguage('vi'); // Set default language
// })();

export const initializeI18n = async () => {
    await loadNamespaceForLanguage('vi', namespaces);
    i18n.changeLanguage('vi');
};
export { loadNamespaceForLanguage };
export default i18n;