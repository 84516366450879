export const routePaths = {
    apiUrl: "https://services.theark.studio/api",
    default: "/",
    translation: "/translations",
    personalTaxAudit:
    {
        fe: "/thue-thu-nhap-ca-nhan",
        api: "/personal-tax-audits",
        subApi: "/personal-tax-audit-income-resources",
    },
    unemploymentAllowances: {
        fe: "/tro-cap-that-nghiep",
        api: "/unemployment-allowances"
    },
    retirementPlan: {
        fe: "/luong-huu",
        api: "/retirement"
    },
    aboutUs: "/ve-chung-toi"
}

export const namespaces = ['common', 'personalTaxAudit', 'landing', 'retirementPlan', 'aboutUs'];