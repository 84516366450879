import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../global-components/sidebar';
import RetirementForm from './retirement-form';


const RetirementPlanDetails = () => {
    const { t } = useTranslation('retirementPlan');
    const retirementExample = t('example', { returnObjects: true });

    const exampleList = Array.isArray(retirementExample?.list) ? retirementExample.list : [];
    return (
        <div className="blog-page-area pd-bottom-80 go-top">
            <div className="container">
                <div className="row">
                    <Sidebar />
                    <div className="col-xl-8 col-lg-6 pd-top-50">
                        <div className='row'>
                            <div className="col-lg-24 col-sm-8 col-20"
                            >
                                <div className="container">
                                    <div className="row pd-bottom-50">
                                        <h3><b>{t('title')}</b></h3>
                                    </div>
                                    <div className="row pd-bottom-30 ">
                                        <h4><b>{t('subTitle')}</b></h4>
                                        <h6>{t('text')}</h6>
                                    </div>
                                    <div className="row features-space pd-bottom-30 ">
                                        <div className="feature-list">
                                            <div className="feature-item">
                                                <h6 className='pd-bottom-30'>{t('example.text')}</h6>
                                                <ul>
                                                    {exampleList.map((text, index) => (
                                                        < li key={index} ><h6>{text}</h6></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-24 col-sm-8 col-20 container">
                                <br /><br />
                                <RetirementForm />
                            </div>
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </div>

        </div>

    )

}

export default RetirementPlanDetails;
