import React, { Component } from 'react';
import FirstHorAdBanner from '../ad-components/1st-hor-ad-banner';
import { useTranslation } from 'react-i18next';

const Footer = () => {

	const { t } = useTranslation('common');
	// componentDidMount() {
	// const $ = window.$;

	// let publicUrl = process.env.PUBLIC_URL + '/'
	// const minscript = document.createElement("script");
	// minscript.async = true;
	// minscript.src = publicUrl + "assets/js/main.js";

	// document.body.appendChild(minscript);

	// var bodyOvrelay = $('#body-overlay');
	// var searchPopup = $('#td-search-popup');

	// $(document).on('click', '#body-overlay', function (e) {
	// 	e.preventDefault();
	// 	bodyOvrelay.removeClass('active');
	// 	searchPopup.removeClass('active');
	// });
	// $(document).on('click', '.search', function (e) {
	// 	e.preventDefault();
	// 	searchPopup.addClass('active');
	// 	bodyOvrelay.addClass('active');
	// });
	// }

	return (
		<div className="footer-area bg-black pd-top-95">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-sm-5 col-24">
						<div className="widget">
							<h5 className="widget-title">{t('footer.aboutUs.text')}</h5>
							<div className="widget_about">
								<p>{t('footer.aboutUs.description')}</p>
								{/* <ul className="social-area social-area-2 mt-4">
									<li><a className="facebook-icon" href="https://www.facebook.com/solverwp/"><i className="fa fa-facebook" /></a></li>
									<li><a className="twitter-icon" href="https://www.twitter.com/solverwp/"><i className="fa fa-twitter" /></a></li>
									<li><a className="youtube-icon" href="https://www.youtube.com/solverwp/"><i className="fa fa-youtube-play" /></a></li>
									<li><a className="instagram-icon" href="https://www.instagram.com/solverwp/"><i className="fa fa-instagram" /></a></li>
									<li><a className="google-icon" href="https://www.google.com/solverwp/"><i className="fa fa-google-plus" /></a></li>
								</ul> */}
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-sm-1"></div>
					<div className="col-lg-4 col-sm-6">
						<div className="widget">
							<h5 className="widget-title">{t('footer.contact.text')}</h5>
							<ul className="contact_info_list">
								<li><i className='fa fa-home' />{t('footer.contact.company')}</li>
								<li><i className='fa fa-barcode' />{t('footer.contact.tax')}</li>
								<li><i className="fa fa-map-marker" />{t('footer.contact.address')}</li>
								<li><i className="fa fa-phone" /> {t('footer.contact.phone')}</li>
								<li><i className="fa fa-envelope-o" />{t('footer.contact.companyLink')}<br />{t('footer.contact.email')}</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="footer-bottom text-center">
					<FirstHorAdBanner />
				</div>
				<div className="footer-bottom text-center">
					<p>{t('footer.contact.copyright')}</p>
					<span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=5GLDhw5LwGW5pnGvPNJ279W8WW7xbTHfix52Su3OO8LfKwPySh2H6joXtBar"></script></span>
				</div>
			</div>
		</div>

	)
}


export default Footer