import { tab } from '@testing-library/user-event/dist/tab';
import React, { } from 'react';
import { useTranslation } from 'react-i18next';

const TaxAuditTableDetails = () => {
    const { t } = useTranslation('personalTaxAudit');
    // Lấy dữ liệu từ dịch vụ với returnObjects để xử lý object và array
    const tableData = t('taxAudit.personal.progressiveTaxRatioForPersonalIncome', { returnObjects: true });

    // Kiểm tra và lấy Body và header
    const tableHeader = Array.isArray(tableData?.header) ? tableData.header : [];
    const tableBody = Array.isArray(tableData?.body) ? tableData.body : [];
    const guidleLines = Array.isArray(tableData?.guildelines) ? tableData.guildelines : [];

    return (
        <div className='tax-table-details'>
            <table>
                <thead>
                    <tr>
                        {tableHeader.map((headerText, index) => (
                            <th key={index}>{headerText}</th>
                        ))}
                    </tr>

                </thead>
                <tbody>
                    {tableBody.map((bodyList, index) => (
                        <tr key={index}>
                            {bodyList.map((bodyText, index) => (
                                <td key={index}>{bodyText}</td>
                            ))
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='tax-audit-steps'>
                <ul>
                    {guidleLines.map((text, index) => (
                        <li key={index}>{text}</li>
                    ))}
                </ul>
            </div>
        </div>

    );

}

export default TaxAuditTableDetails;