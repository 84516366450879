import React, { useState, useEffect } from 'react';
import i18n, { initializeI18n } from './i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { routePaths } from './const/variable.const';
import PersonalTaxAuditDetailsPage from './pages/personal-tax-audit';
import Error from './components/global-components/error';
import LayoutMain from './layout/main';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LandingPage from './pages/landing';
import AboutUsPage from './pages/about-us';
import RetirementPlanPage from './pages/retirement-plan';

function App() {
  // useEffect(() => {
  //   const clearTranslationCacheOnStartup = (language, namespaces) => {
  //     namespaces.forEach((ns) => {
  //       localStorage.removeItem(`${language}_${ns}`);
  //       localStorage.removeItem(`${language}_${ns}_expiry`);
  //     });
  //   };

  //   clearTranslationCacheOnStartup('vi', ['common', 'personalTaxAudit', 'landing']); // Các namespace cần xóa cache
  // }, []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeTranslations = async () => {
      await initializeI18n();
      setLoading(false);
    };

    initializeTranslations();
  }, []);

  if (loading) {
    return <div>Loading translations...</div>;
  }
  return (
    <Router>
      <div className="App">
        {/* Đặt ToastContainer để toast có thể hiển thị */}
        <ToastContainer />
      </div>
      <div>
        {/* Define Routes */}
        <Routes>
          {/* <Route path="/" element={<PersonalTaxAuditDetailsPage />} /> */}
          <Route path="/" element={<LayoutMain />}>
            <Route index element={<LandingPage />} />
          </Route>
          <Route path={routePaths.personalTaxAudit.fe} element={<LayoutMain />}>
            <Route index element={<PersonalTaxAuditDetailsPage />} />
          </Route>
          <Route path={routePaths.retirementPlan.fe} element={<LayoutMain />}>
            <Route index element={<RetirementPlanPage />} />
          </Route>
          <Route path={routePaths.aboutUs} element={<LayoutMain />}>
            <Route index element={<AboutUsPage />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;