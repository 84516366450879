import React, { } from 'react';
import containerLeftBg from "../../assets/landing/container-l-bg.png";
import containerRightBg from "../../assets/landing/container-r-bg.png";

const SidebarBackgroundImage = (props) => {

  return (
    <div className="col-xl-2 col-lg-3 col-24 pd-top-50 go-top">
      {props.img === 'L' ?
        (<div className="category-sitebar" style={{ backgroundImage: `url(${containerLeftBg})` }} />)
        :
        (<div className="category-sitebar" style={{ backgroundImage: `url(${containerRightBg})` }} />)
      }

    </div>
  )
}

export default SidebarBackgroundImage;
