import React, { useEffect } from 'react';

const FirstVerAdBanner = (props) => {
    useEffect(() => {
        document.cookie = "myCookie=cookieValue; SameSite=None; Secure";

        // Check that adBanner has a valid width
        // const adBanner = document.getElementById('ad-banner-ver');

        // if (adBanner) {
        //     const checkAdBannerSize = () => {
        //         if (adBanner.offsetWidth > 0) {
        //             // Initialize ad
        //             const adScript = document.createElement('script');
        //             adScript.innerHTML = `(adsbygoogle = window.adsbygoogle || []).push({});`;
        //             adBanner.appendChild(adScript);
        //         } else {
        //             // Wait until  the width is value (100ms/each time)
        //             checkAdBannerSize();
        //             setTimeout(checkAdBannerSize, 100);
        //         }
        //     };
        // }


    }, []);

    return (
        <div
            id="ad-banner-ver"
            style={{
                maxHeight: "15vw !important",
                height: props.height,
                textAlign: 'center'
            }}
            dangerouslySetInnerHTML={{
                __html: `
                    <ins class="adsbygoogle"
                    style="display:block"
                    data-ad-client="ca-pub-8964258135645900"
                    data-ad-slot="1414504772"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                `,
            }}
        />
    );
};

export default FirstVerAdBanner;