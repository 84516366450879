import React, { Component } from 'react';
import FirstVerAdBanner from '../ad-components/1st-ver-ad-banner';

class Sidebar extends Component {
  render() {
    return (
      <div className="col-xl-2 col-lg-3 col-24 pd-top-50 go-top">
        <div className="category-sitebar">
          <div className="widget widget-category">
            {/* <h6 className="widget-title"></h6> */}
            <FirstVerAdBanner />
          </div>
          <div className="widget widget-category">
            {/* <h6 className="widget-title"></h6> */}
            <FirstVerAdBanner />
          </div>
          <div className="widget widget-category">
            {/* <h6 className="widget-title"></h6> */}
            <FirstVerAdBanner />
          </div>
        </div>
      </div>
    )
  }
}

export default Sidebar;
