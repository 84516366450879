import React, { useEffect, useState } from 'react';
import { routePaths } from '../../const/variable.const';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import axiosInstance from '../../http-common-api';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/vi'; // Import tiếng Việt cho moment
import { toast } from 'react-toastify';

moment.locale('vi'); // Set locale tiếng Việt

const RetirementForm = () => {
    const { t } = useTranslation('retirementPlan');
    // Lấy dữ liệu từ dịch vụ với returnObjects để xử lý object và array
    const retirementVariables = t('form.variables', { returnObjects: true });
    const caseOfRetirement = t('form.caseOfRetirement', { returnObjects: true });
    // const taxFreeItem = t('taxAudit.personal.formFill.taxFreeItem', { returnObjects: true });
    // const resultTable = t('taxAudit.personal.formFill.resultTable', { returnObjects: true });

    const [state, setState] = useState({
        errors: {},
        form: {
            gender: 'M',
            averageInsuranceSalary: 0,
            consecutiveYearsOfSocialInsuranceContributions: 0,
            caseOfRetirement: "Q",

        },
        entitledOfAverageSalary: 0,
        estimatedMonthlyPension: 0,
        isTableVisible: false,
        isCalculate: false,
        isCompletedSubmitForm: false
    }
    );

    // Effect to handle when grade list calculation is complete
    useEffect(() => {
        if (state.isCalculate) {
            // console.log("Complete calculate: ", state);
            // submitForm();
        }
    }, [state.isCalculate]); // Only triggers when `isCompletedCalculateGradeList` changes

    useEffect(() => {
        // console.log("state error: ", state)
    }, [state.errors]);

    const toggleCollapse = () => {
        setState(prevState => ({
            ...prevState,
            isTableVisible: !prevState.isTableVisible
        }));
    };

    const formatCurrency = (currency) => {
        // Định dạng số với dấu phân cách
        return currency.toLocaleString('vi-VN');
    };

    const parseCurrencyString = (currencyString) => {
        const cleanedString = currencyString.replace(/\./g, '');
        return parseFloat(cleanedString) || 0;
    };

    const validateFields = () => {
        const { form } = state;
        let valid = true;
        let errors = {};

        if (!form.averageInsuranceSalary) {
            valid = false;
            errors[`averageInsuranceSalary`] = t('requiredErrorText');
        }
        if (!form.consecutiveYearsOfSocialInsuranceContributions) {
            valid = false;
            errors[`consecutiveYearsOfSocialInsuranceContributions`] = t('requiredErrorText');
        } else if (form.consecutiveYearsOfSocialInsuranceContributions < retirementVariables.minimumTotalYearContributions[form.gender]) {
            valid = false;
            errors[`consecutiveYearsOfSocialInsuranceContributions`] = t('form.consecutiveYearsOfSocialInsuranceContributions.errorRequiredText').replace("$yearFilterByGender", retirementVariables.minimumTotalYearContributions[form.gender]);
        }

        setState(prevState => ({
            ...prevState,
            errors: errors,
        }));

        return valid;
    };


    const handleTextChange = (e, field) => {
        const updateForm = state.form;
        updateForm[field] = e.target.value
        setState(prevState => ({
            ...prevState,
            form: updateForm,
        }));
    };

    const handleAmountChange = (e, field) => {
        const updateForm = state.form;
        updateForm[field] = formatNumber(e.target.value);

        // Cập nhật state với forms mới và giữ nguyên các thuộc tính khác của state
        setState((prevState) => ({
            ...prevState,
            form: updateForm,
        }));
    };

    const formatNumber = (value) => {
        // Remove all non-numeric characters except dots
        const numericValue = value.replace(/\D/g, '');
        // Format number with thousands separator
        return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const calculateRetirementSalary = () => {
        if (!validateFields()) return;
        const { form } = state;
        const minimumTotalYearContributions = retirementVariables.minimumTotalYearContributions[form.gender];

        let entitledOfAverageSalary = retirementVariables.entitledOfAverageSalary + ((parseCurrencyString(form.consecutiveYearsOfSocialInsuranceContributions) - minimumTotalYearContributions) * retirementVariables.percentEachYear)

        entitledOfAverageSalary = entitledOfAverageSalary > 75 ? 75 : entitledOfAverageSalary;

        let estimatedMonthlyPension = entitledOfAverageSalary * parseCurrencyString(form.averageInsuranceSalary) * 0.01

        setState((prevState) => ({
            errors: state.errors,
            form: state.form,
            entitledOfAverageSalary: entitledOfAverageSalary,
            estimatedMonthlyPension: estimatedMonthlyPension,
            isTableVisible: true,
            isCalculate: true,
            isCompletedSubmitForm: true
        }));

    };

    const submitForm = async () => {
        const forms = state.forms;
        const selectedYear = new Date(Date.parse(forms[0].fromMonth)).getFullYear();
        const totalIncomeResouces = forms.length;
        let personalTaxAuditIncomeResources = [];
        const promises = forms.map(form => {
            const fromMonthDate = new Date(Date.parse(forms[0].fromMonth));
            const toMonthDate = new Date(Date.parse(forms[0].toMonth));
            return axiosInstance.post(`${routePaths.apiUrl}${routePaths.personalTaxAudit.subApi}`, {
                data: {
                    ...form,
                    fromMonth: fromMonthDate.toLocaleDateString('en-CA'),
                    toMonth: toMonthDate.toLocaleDateString('en-CA'),
                    income: parseCurrencyString(form.income),
                    personalAllowances: parseCurrencyString(form.personalAllowances),
                    familyAllowances: parseCurrencyString(form.familyAllowances),
                    taxFreeItem: parseCurrencyString(form.taxFreeItem),
                    taxableIncome: form.taxableIncome,
                    taxableIncomePaid: form.taxableIncomePaid
                }
            }).then((res) => {
                personalTaxAuditIncomeResources.push(res.data.data.id)
            }).catch((error) => {
                toast.error(error.message, {
                    autoClose: false, // Không tự đóng
                    closeOnClick: true, // Cho phép đóng khi người dùng click
                    closeButton: true,  // Hiển thị nút đóng
                });

            })
        })

        await Promise.all(promises);

        await axiosInstance.post(`${routePaths.apiUrl}${routePaths.personalTaxAudit.api}`, {
            data: {
                selectedYear: selectedYear.toString(),
                totalIncomeResouces: totalIncomeResouces,
                totalPITTaxAmount: state.totalPITTaxAmount * 1000000,
                totalTaxableIncomePaid: state.totalTaxableIncomePaid,
                grade: state.grade,
                personal_tax_audit_income_resources: personalTaxAuditIncomeResources
            }

        }).then((response) => {
            setState((prevState) => ({
                ...prevState,
                isCompletedSubmitForm: true
            }));
        }).catch((error) => {
            toast.error(error)
            setState((prevState) => ({
                ...prevState,
                isCompletedSubmitForm: false
            }));
        })
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="header-content">
                    <h4>{t("form.title")}</h4>
                </div>
            </div>
            <div className="field-row">
                <div className='col-sm-24 col-md-24 col-4'>
                    <label>{t("form.gender.title")}</label>
                </div>
                <div className='col-10 col-sm-24 col-md-24'>
                    <div className="radio-group">
                        <label className="radio-label">
                            <input
                                type="radio"
                                value="M"
                                checked={state.form?.gender === 'M'}
                                onChange={(e) => handleTextChange(e, 'gender')}
                            />
                            {t("form.gender.M")}
                        </label>
                        <label className="radio-label">
                            <input
                                type="radio"
                                value="F"
                                checked={state.form?.gender === 'F'}
                                onChange={(e) => handleTextChange(e, 'gender')}
                            />
                            {t("form.gender.F")}
                        </label>
                    </div>
                </div>
            </div>
            <div className="field-row">
                <div className='col-sm-24 col-md-24 col-4'>
                    <label>{t("form.caseOfRetirementTitle")}</label>
                </div>
                <div className='col-10 col-sm-24 col-md-24'>
                    <select
                        value={state.form?.caseOfRetirement || 'Q'}
                        // onChange={(e) => handleTextChange(e, 'caseOfRetirement')}
                        className="select-field"
                        disabled
                    >
                        {caseOfRetirement.map((obj, index) => (
                            <option value={obj.value} key={index}>{obj.label}</option>
                        ))}
                        {/* Thêm các tùy chọn khác nếu cần */}
                    </select>
                </div>
            </div>
            <div className="field-row">
                <div className='col-sm-24 col-md-24 col-4'>
                    <label>{t("form.averageInsuranceSalary")}&nbsp;({t("currency")})</label>
                </div>
                <div className='col-10 col-sm-24 col-md-24'>
                    <input
                        type="text"
                        value={state.form?.averageInsuranceSalary || ''}
                        onChange={(e) => handleAmountChange(e, 'averageInsuranceSalary')}
                        className="input-amount"
                    />
                    {state.errors?.[`averageInsuranceSalary`] && <div className="error">{state.errors?.[`averageInsuranceSalary`]}</div>}
                </div>
            </div>
            <div className="field-row">
                <div className='col-sm-24 col-md-24 col-4'>
                    <label>{t("form.consecutiveYearsOfSocialInsuranceContributions.text")}</label>
                </div>
                <div className='col-10 col-sm-24 col-md-24'>
                    <input
                        type="text"
                        value={state.form?.consecutiveYearsOfSocialInsuranceContributions || ''}
                        onChange={(e) => handleAmountChange(e, 'consecutiveYearsOfSocialInsuranceContributions')}
                        className="input-amount"
                    />
                    {state.errors?.[`consecutiveYearsOfSocialInsuranceContributions`] && <div className="error">{state.errors?.[`consecutiveYearsOfSocialInsuranceContributions`]}</div>}
                </div>
            </div>
            <button type="button" onClick={calculateRetirementSalary} className="btn-submit">
                <i className='fa fa-balance-scale' />
                &nbsp;
                {t("form.execution")}
            </button>
            {/* Collapse Section */}
            {
                state.isCompletedSubmitForm ?
                    <div className={`collapse-section ${state.isTableVisible ? 'open' : ''}`}>
                        <div className="card-header" onClick={toggleCollapse}>
                            <h5 className="mb-0">
                                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#tax-audit-table-details" aria-expanded="true" aria-controls="tax-audit-table-details">
                                    {t("result.title")}
                                </button>
                            </h5>
                        </div>
                        <div className="collapse-body">
                            <div className='collapse-summary'>
                                <div className='row total-pit-amount'>
                                    <div className='col-4'>
                                        {t("result.retirementBenefitRate")}
                                    </div>
                                    <div className='col-4'>
                                        <i className='fa fa-percent' />
                                        &nbsp;&nbsp;&nbsp;
                                        <span style={{ color: "red" }}>{formatCurrency(state.entitledOfAverageSalary)}</span>
                                        &nbsp;
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div className='collapse-summary'>
                                <div className='row total-pit-amount'>
                                    <div className='col-4'>
                                        {t("result.estimatedMonthlyPension")}
                                    </div>
                                    <div className='col-4'>
                                        <i className='fa fa-percent' />
                                        &nbsp;&nbsp;&nbsp;
                                        <span style={{ color: "red" }}>{formatCurrency(state.estimatedMonthlyPension)}</span>
                                        &nbsp; {t("currency")}
                                    </div>
                                </div>
                                <br />
                            </div>

                        </div>
                    </div>
                    : null
            }
        </div>
    );

}

export default RetirementForm;
