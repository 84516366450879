import React, { useEffect, useState } from 'react';
import { routePaths } from '../../../const/variable.const';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import axiosInstance from '../../../http-common-api';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/vi'; // Import tiếng Việt cho moment
import { toast } from 'react-toastify';

moment.locale('vi'); // Set locale tiếng Việt

const FormFill = () => {
    const { t } = useTranslation('personalTaxAudit');
    // Lấy dữ liệu từ dịch vụ với returnObjects để xử lý object và array
    const taxableIncome = t('taxAudit.personal.formFill.taxableIncome', { returnObjects: true });
    const personalAllowances = t('taxAudit.personal.formFill.personalAllowances', { returnObjects: true });
    const familyAllowances = t('taxAudit.personal.formFill.familyAllowances', { returnObjects: true });
    const taxFreeItem = t('taxAudit.personal.formFill.taxFreeItem', { returnObjects: true });
    const resultTable = t('taxAudit.personal.formFill.resultTable', { returnObjects: true });

    const [state, setState] = useState({
        errors: {},
        meaning: {
            taxableIncomePaidDesc: taxableIncome.description,
            personalAllowancesDesc: personalAllowances.description,
            familyAllowancesDesc: familyAllowances.description,
            taxFreeItemDesc: taxFreeItem.description
        },
        forms: [
            {
                job: '',
                fromMonth: '',
                toMonth: '',
                income: '',
                personalAllowances: '11.000.000',
                familyAllowances: '',
                taxFreeItem: '',
                taxableIncome: 0,
                taxableIncomePaid: 0
            },
        ],
        totalPITTaxAmount: 0,
        totalTaxableIncomePaid: 0,
        isTableVisible: false,
        selectedYear: '',
        grade: 0,
        gradeList: [
            {
                id: 1,
                from: 0,
                to: 60,
                taxableIncomeText: "",
                taxRate: 5,
                amount: 0,
                taxAmount: 0
            },
            {
                id: 2,
                taxableIncomeText: "",
                from: 60,
                to: 120,
                taxRate: 10,
                amount: 0,
                taxAmount: 0
            },
            {
                id: 3,
                taxableIncomeText: "",
                from: 120,
                to: 216,
                taxRate: 15,
                amount: 0,
                taxAmount: 0
            },
            {
                id: 4,
                taxableIncomeText: "",
                from: 216,
                to: 384,
                taxRate: 20,
                amount: 0,
                taxAmount: 0
            },
            {
                id: 5,
                taxableIncomeText: "",
                from: 384,
                to: 624,
                taxRate: 25,
                amount: 0,
                taxAmount: 0
            },
            {
                id: 6,
                taxableIncomeText: "",
                from: 624,
                to: 868,
                taxRate: 30,
                amount: 0,
                taxAmount: 0
            },
            {
                id: 7,
                taxableIncomeText: "",
                from: 868,
                to: Infinity,
                taxRate: 35,
                amount: 0,
                taxAmount: 0
            }
        ],
        finalGradeList: [],
        isCompletedCalculateTotalAmount: false,
        isCompletedCalculateGradeList: false,
        isCompletedSubmitForm: false
    }
    );

    // Effect to handle when total amount calculation is complete
    useEffect(() => {
        if (state.isCompletedCalculateTotalAmount) {
            // console.log("Complete calculate total amount:", state);
            calculateGradeList();
        }
    }, [state.isCompletedCalculateTotalAmount]); // Only triggers when `isCompletedCalculateTotalAmount` changes

    // Effect to handle when grade list calculation is complete
    useEffect(() => {
        if (state.isCompletedCalculateGradeList) {
            // console.log("Complete calculate grade:", state);
            submitForm();
        }
    }, [state.isCompletedCalculateGradeList]); // Only triggers when `isCompletedCalculateGradeList` changes


    const toggleCollapse = () => {
        setState(prevState => ({ isTableVisible: !prevState.isTableVisible }));
    };

    const addForm = () => {
        setState((prevState) => ({
            forms: [...prevState.forms, { job: '', fromMonth: '', toMonth: '', income: '', personalAllowances: '11.000.000', familyAllowances: '', taxFreeItem: '' }],
        }));
    };

    const deleteForm = (index) => {
        setState((prevState) => {
            const newForms = prevState.forms.filter((_, i) => i !== index);
            return { forms: newForms };
        });
    };

    const formatCurrency = (currency) => {
        // Định dạng số với dấu phân cách
        return currency.toLocaleString('vi-VN');
    };

    const parseCurrencyString = (currencyString) => {
        const cleanedString = currencyString.replace(/\./g, '');
        return parseFloat(cleanedString) || 0;
    };

    const validateFields = () => {
        const { forms } = state;
        let valid = true;
        let errors = {};

        forms.forEach((form, index) => {
            if (!form.fromMonth) {
                valid = false;
                errors[`fromMonth_${index}`] = t('monthSelect.fromMonth.requiredErrorText');
            }
            if (!form.toMonth) {
                valid = false;
                errors[`toMonth_${index}`] = t('monthSelect.toMonth.requiredErrorText');
            }
            if (form.fromMonth && form.toMonth && new Date(form.fromMonth) > new Date(form.toMonth)) {
                valid = false;
                errors[`toMonth_${index}`] = t('monthSelect.toMonth.toMonthLargerFromMonthErrorText');
            }
        });

        setState({ errors });
        return valid;
    };

    const handleMonthChange = (e, index, field) => {
        const newForms = [...state.forms];
        const form = { ...newForms[index] };
        form[field] = e.target.value;
        newForms[index] = form;

        // Cập nhật state với forms mới và giữ nguyên các thuộc tính khác của state
        setState((prevState) => ({
            ...prevState,
            forms: newForms,
        }));
    };

    const handleAmountChange = (e, index, field) => {
        const newForms = [...state.forms];
        const form = { ...newForms[index] };

        form[field] = formatNumber(e.target.value);
        newForms[index] = form;

        // Cập nhật state với forms mới và giữ nguyên các thuộc tính khác của state
        setState((prevState) => ({
            ...prevState,
            forms: newForms,
        }));
    };

    const handleJobChange = (e, index) => {
        const newForms = [...state.forms];
        const form = { ...newForms[index] };

        form.job = e.target.value;
        newForms[index] = form;

        // Cập nhật state với forms mới và giữ nguyên các thuộc tính khác của state
        setState((prevState) => ({
            ...prevState,
            forms: newForms,
        }));
    };


    const formatNumber = (value) => {
        // Remove all non-numeric characters except dots
        const numericValue = value.replace(/\D/g, '');
        // Format number with thousands separator
        return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const calculateTotalMonths = (form) => {
        const { fromMonth, toMonth } = form;
        if (fromMonth && toMonth) {
            const start = moment(fromMonth);
            const end = moment(toMonth);
            const totalMonths = end.diff(start, 'months') + 1;;
            return totalMonths >= 0 ? totalMonths : 0;
        }
        return 0;
    };

    const calculateTotalAmount = () => {
        if (!validateFields()) return;
        const { forms } = state;
        let totalTaxableIncomePaid = 0;
        let updatedForms = forms.map(form => {
            const totalMonths = calculateTotalMonths(form);
            const income = parseCurrencyString(form.income) || 0;
            const personalAllowances = parseCurrencyString(form.personalAllowances) || 0;
            const familyAllowances = parseCurrencyString(form.familyAllowances) || 0;
            const taxFree = parseCurrencyString(form.taxFreeItem) || 0;
            const taxableIncome = income - personalAllowances - familyAllowances - taxFree;
            const taxableIncomePaid = taxableIncome * totalMonths;
            if (taxableIncomePaid > 0)
                totalTaxableIncomePaid += taxableIncomePaid
            return { ...form, taxableIncome, taxableIncomePaid };
            // total += (income + familyAllowances + taxFreeItem);
        });
        setState((prevState) => ({
            // ...prevState,
            errors: state.errors,
            meaning: state.meaning,
            forms: updatedForms,
            totalPITTaxAmount: state.totalPITTaxAmount,
            totalTaxableIncomePaid: totalTaxableIncomePaid,
            isTableVisible: true,
            selectedYear: state.selectedYear,
            grade: state.grade,
            gradeList: state.gradeList,
            finalGradeList: state.finalGradeList,
            isCompletedCalculateTotalAmount: true,
            isCompletedCalculateGradeList: false,
            isCompletedSubmitForm: false

        }));

    };

    const calculateGradeList = () => {
        const { gradeList, totalTaxableIncomePaid } = state;
        if (totalTaxableIncomePaid < 0)
            return;

        let remainingIncome = totalTaxableIncomePaid / 1000000;
        const grade = gradeList.find(g => remainingIncome <= g.to);
        let totalPITTaxAmount = 0;
        let finalGradeList = gradeList.filter(g => g.id <= grade.id)
            .map(g1 => {
                let from = g1.from;
                let to = g1.to;
                let amount = g1.amount;
                let taxAmount = g1.taxAmount;
                let taxableIncomeText = g1.taxableIncomeText;

                if (remainingIncome >= 0) {
                    let checkRemaining = remainingIncome - (g1.to - g1.from);
                    if (checkRemaining < 0) {
                        to = remainingIncome + from;
                        amount = remainingIncome;
                    } else {
                        amount = to - from;
                    }
                    taxAmount = amount * (g1.taxRate / 100);
                    if (g1.id === 1)
                        taxableIncomeText = t("TO") + " " + to;
                    else
                        taxableIncomeText = t("from") + " " + from + " " + t("to") + " " + to;
                    remainingIncome -= (g1.to - g1.from);
                    totalPITTaxAmount += taxAmount
                    return {
                        ...g1,
                        to: to,
                        amount: amount,
                        taxAmount: taxAmount,
                        taxableIncomeText: taxableIncomeText
                    };
                }
                return {}
            });

        setState((prevState) => ({
            ...prevState,
            finalGradeList: finalGradeList,
            totalPITTaxAmount: totalPITTaxAmount,
            grade: grade.id,
            isCompletedCalculateGradeList: true
        }));
    }

    const submitForm = async () => {
        const forms = state.forms;
        const selectedYear = new Date(Date.parse(forms[0].fromMonth)).getFullYear();
        const totalIncomeResouces = forms.length;
        let personalTaxAuditIncomeResources = [];
        const promises = forms.map(form => {
            const fromMonthDate = new Date(Date.parse(forms[0].fromMonth));
            const toMonthDate = new Date(Date.parse(forms[0].toMonth));
            return axiosInstance.post(`${routePaths.apiUrl}${routePaths.personalTaxAudit.subApi}`, {
                data: {
                    ...form,
                    fromMonth: fromMonthDate.toLocaleDateString('en-CA'),
                    toMonth: toMonthDate.toLocaleDateString('en-CA'),
                    income: parseCurrencyString(form.income),
                    personalAllowances: parseCurrencyString(form.personalAllowances),
                    familyAllowances: parseCurrencyString(form.familyAllowances),
                    taxFreeItem: parseCurrencyString(form.taxFreeItem),
                    taxableIncome: form.taxableIncome,
                    taxableIncomePaid: form.taxableIncomePaid
                }
            }).then((res) => {
                personalTaxAuditIncomeResources.push(res.data.data.id)
            }).catch((error) => {
                toast.error(error.message, {
                    autoClose: false, // Không tự đóng
                    closeOnClick: true, // Cho phép đóng khi người dùng click
                    closeButton: true,  // Hiển thị nút đóng
                });

            })
        })

        await Promise.all(promises);

        await axiosInstance.post(`${routePaths.apiUrl}${routePaths.personalTaxAudit.api}`, {
            data: {
                selectedYear: selectedYear.toString(),
                totalIncomeResouces: totalIncomeResouces,
                totalPITTaxAmount: state.totalPITTaxAmount * 1000000,
                totalTaxableIncomePaid: state.totalTaxableIncomePaid,
                grade: state.grade,
                personal_tax_audit_income_resources: personalTaxAuditIncomeResources
            }

        }).then((response) => {
            setState((prevState) => ({
                ...prevState,
                isCompletedSubmitForm: true
            }));
        }).catch((error) => {
            toast.error(error)
            setState((prevState) => ({
                ...prevState,
                isCompletedSubmitForm: false
            }));
        })

    }

    const startYear = 2000;
    const endYear = new Date().getFullYear();
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }

    return (
        <div>
            {Array.isArray(state.forms) && state.forms.length > 0 && state.forms.map((form, index) => (
                <div key={index} className="card">
                    <div className="card-header">
                        <div className="header-content">
                            <h4>{t("income")} {index + 1}</h4>
                            <div className="header-buttons">
                                <button
                                    type="button"
                                    onClick={() => deleteForm(index)}
                                    className="btn-delete"
                                    disabled={state.forms.length === 1}
                                >
                                    {t("delete")}
                                </button>
                                <button type="button" onClick={addForm} className="btn-add">
                                    {t("add")}
                                </button>
                            </div>
                        </div>
                        <div className="date-selectors">
                            <div className="month-selectors row">
                                <div className='col-xl-5 col-lg-8 col-md-6 col-24 row'>
                                    <div className='col-5'>
                                        <label>
                                            {t("monthSelect.fromMonth.text")}<span style={{ color: "red" }}>*</span>:
                                        </label>
                                    </div>
                                    <div className='col-6'>
                                        <input
                                            type="month"
                                            value={state.forms[index]?.fromMonth || ''}
                                            onChange={(e) => handleMonthChange(e, index, 'fromMonth')}
                                            className="input-month"
                                        />
                                        {state.errors?.[`fromMonth_${index}`] && <div className="error">{t("requiredErrorText")}</div>}
                                    </div>
                                </div>
                                <div className='col-xl-5  col-lg-8 col-md-6 col-24 row'>
                                    <div className='col-5'>
                                        <label>
                                            {t("monthSelect.toMonth.text")}<span style={{ color: "red" }}>*</span>:
                                        </label>
                                    </div>
                                    <div className='col-6'>
                                        <input
                                            type="month"
                                            value={state.forms[index]?.toMonth || ''}
                                            onChange={(e) => handleMonthChange(e, index, 'toMonth')}
                                            className="input-month"
                                        />
                                        {state.errors?.[`toMonth_${index}`] && <div className="error">{t("requiredErrorText")}</div>}
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-5 col-md-3 col-24'>
                                    <div className="total-months">
                                        {t("monthSelect.totalMonth")}: {calculateTotalMonths(state.forms[index])}
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="form-fields">
                            <div className="field-row">
                                <div className='col-4 col-sm-24'>
                                    <label>
                                        {t("taxAudit.personal.formFill.job")}
                                    </label>
                                </div>
                                <div className='col-10 col-sm-24'>
                                    <input
                                        type="text"
                                        value={state.forms[index]?.job || ''}
                                        onChange={(e) => handleJobChange(e, index)}
                                        className="input-text"
                                    />
                                </div>
                            </div>
                            <div className="field-row">
                                <div className='col-sm-24 col-md-24 col-4'>
                                    <label>{t("taxAudit.personal.formFill.taxableIncome.text")}&nbsp;({t("taxAudit.personal.formFill.currency")})</label>
                                </div>
                                <div className='col-10 col-sm-24 col-md-24'>
                                    <input
                                        type="text"
                                        value={state.forms[index]?.income || ''}
                                        onChange={(e) => handleAmountChange(e, index, 'income')}
                                        className="input-amount"
                                    />
                                    <Tippy content={<span dangerouslySetInnerHTML={{ __html: state.meaning?.taxableIncomePaidDesc }} />}>
                                        <span className="info-icon">
                                            <i className='	fa fa-question-circle-o' />
                                        </span>
                                    </Tippy>
                                </div>

                            </div>
                            <div className="field-row">
                                <div className='col-4'>
                                    <label>
                                        {t("taxAudit.personal.formFill.personalAllowances.text")}&nbsp;({t("taxAudit.personal.formFill.currency")})
                                    </label>
                                </div>
                                <div className='col-10'>
                                    <input
                                        type="text"
                                        value={state.forms[index]?.personalAllowances}
                                        className="input-amount"
                                        disabled
                                    />
                                    <Tippy content={<span dangerouslySetInnerHTML={{ __html: state.meaning?.personalAllowancesDesc }} />}>
                                        <span className="info-icon">
                                            <i className='fa fa-question-circle-o' />
                                        </span>
                                    </Tippy>
                                </div>
                            </div>
                            <div className="field-row">
                                <div className='col-4'>
                                    <label>
                                        {t("taxAudit.personal.formFill.familyAllowances.text")}&nbsp;({t("taxAudit.personal.formFill.currency")})
                                    </label>
                                </div>
                                <div className='col-10'>
                                    <input
                                        type="text"
                                        value={state.forms[index]?.familyAllowances || ''}
                                        onChange={(e) => handleAmountChange(e, index, 'familyAllowances')}
                                        className="input-amount"
                                    />
                                    <Tippy content={<span dangerouslySetInnerHTML={{ __html: state.meaning?.familyAllowancesDesc }} />}>
                                        <span className="info-icon">
                                            <i className='	fa fa-question-circle-o' />
                                        </span>
                                    </Tippy>
                                </div>
                            </div>
                            <div className="field-row">
                                <div className='col-4'>
                                    <label>
                                        {t("taxAudit.personal.formFill.taxFreeItem.text")}&nbsp;({t("taxAudit.personal.formFill.currency")})
                                    </label>
                                </div>
                                <div className='col-10'>
                                    <input
                                        type="text"
                                        value={state.forms[index]?.taxFreeItem || ''}
                                        onChange={(e) => handleAmountChange(e, index, 'taxFreeItem')}
                                        className="input-amount"
                                    />
                                    <Tippy content={<span dangerouslySetInnerHTML={{ __html: state.meaning?.taxFreeItemDesc }} />}>
                                        <span className="info-icon">
                                            <i className='	fa fa-question-circle-o' />
                                        </span>
                                    </Tippy>
                                </div>
                            </div>
                            {/* <div className="card-total">
                                    Tổng tiền cho Thu nhập {index + 1}: {calculateCardTotal(index).toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}
                                </div> */}
                        </div>
                    </div>
                </div>
            ))}
            <button type="button" onClick={calculateTotalAmount} className="btn-submit">
                <i className='fa fa-balance-scale' />
                &nbsp;
                {t("taxAudit.personal.formFill.execution")}
            </button>
            {/* Collapse Section */}
            {
                state.isCompletedSubmitForm ?
                    <div className={`collapse-section ${state.isTableVisible ? 'open' : ''}`}>
                        <div className="card-header" onClick={toggleCollapse}>
                            <h5 className="mb-0">
                                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#tax-audit-table-details" aria-expanded="true" aria-controls="tax-audit-table-details">
                                    {t("taxAudit.personal.formFill.totalTaxableIncomePaid")} = <span style={{ color: "red" }}>{formatCurrency(state.totalTaxableIncomePaid)}</span>&nbsp;&nbsp;{t("taxAudit.personal.formFill.currency")}&nbsp;&nbsp;
                                    <i className="fa fa-angle-down" />
                                </button>
                            </h5>
                        </div>
                        <div className="collapse-body">
                            <div className='collapse-summary'>
                                <div className='row total-pit-amount'>
                                    <div className='col-8'>
                                        {t("taxAudit.personal.formFill.totalTaxableIncomePaidDesc")}
                                    </div>
                                    <div className='col-4'>
                                        <i className='fa fa-money' />
                                        &nbsp;&nbsp;&nbsp;
                                        <span style={{ color: "red" }}>{formatCurrency(state.totalTaxableIncomePaid)}</span>
                                        &nbsp; {t("taxAudit.personal.formFill.currency")}
                                    </div>
                                </div>
                                <br />
                                <div className='row total-grade'>
                                    <div className='col-8'>
                                        {t("taxAudit.personal.formFill.yourGrade")}
                                    </div>
                                    <div className='col-4'>
                                        <i className='fa fa-sort-amount-asc' />
                                        &nbsp;&nbsp;&nbsp;
                                        <span style={{ color: "red" }}>{state.grade}</span>
                                        &nbsp; {t("taxAudit.personal.formFill.grade")}
                                    </div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-8'>
                                        {t("taxAudit.personal.formFill.applyProgressive")}
                                    </div>

                                </div>
                                <br />
                            </div>
                            <table className="summary-table">
                                <thead>
                                    <tr>
                                        {resultTable.map((headerText, index) => (
                                            <th key={index}>{headerText}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.finalGradeList.map((grade, index) => {
                                            return (
                                                <tr key={grade.id}>
                                                    <td>{grade.id}</td>
                                                    <td>{grade.taxableIncomeText}</td>
                                                    <td>{grade.taxRate}</td>
                                                    <td>{grade.to - grade.from} x {grade.taxRate}% = {grade.taxAmount}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="3"><strong>{t("taxAudit.personal.formFill.totalTaxableIncomePaid")}:</strong></td>
                                        <td><strong><span style={{ color: "red" }}>{formatCurrency(state.totalTaxableIncomePaid)}</span> {t("taxAudit.personal.formFill.currency")}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3"><strong>{t('taxAudit.personal.formFill.totalPITTaxAmount')}</strong></td>
                                        <td><strong><span style={{ color: "red" }}>{formatCurrency(state.totalPITTaxAmount * 1000000)}</span> {t("taxAudit.personal.formFill.currency")}</strong></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    : null
            }
        </div>
    );

}

export default FormFill;
