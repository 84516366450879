import React from 'react';
import PageHeader from '../components/global-components/page-header';
import { useTranslation } from 'react-i18next';
import RetirementPlanDetails from '../components/retirement-plan/retirement-details';

const RetirementPlanPage = () => {
    const { t } = useTranslation('common');
    return (
        <div>
            <PageHeader headertitle={t('navbar.retirementPlan')}
            // subheader={t('navbar.personal')}
            />
            <RetirementPlanDetails />
        </div>

    );
}

export default RetirementPlanPage