import React from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/global-components/sidebar';


const AboutUsPage = () => {
    const { t } = useTranslation('aboutUs');
    const productMissionData = t('productMission', { returnObjects: true });

    const productMissionList = Array.isArray(productMissionData?.list) ? productMissionData.list : [];


    return (
        <div>
            <div className="row">
                <Sidebar />
                <div className="col-xl-8 col-lg-6 pd-top-50" style={{ lineHeight: "1.8vw" }}>
                    <div className="row">
                        <div className='container'>
                            <div className="row pd-bottom-50">
                                <h3><b>{t('title')}</b></h3>
                            </div>
                            <div className="row pd-bottom-30 ">
                                <h4><b>{t('productVision.title')}</b></h4>
                                <h6>{t('productVision.text')}</h6>
                            </div>
                            <div className="row features-space pd-bottom-30 ">
                                <h5><b>{t('productMission.title')}</b></h5>
                                <div className="feature-list">
                                    <div className="feature-item">
                                        <h6 className='pd-bottom-30'>{t('productMission.text')}</h6>
                                        <ul>
                                            {productMissionList.map((text, index) => (
                                                < li key={index} ><h6>{text}</h6></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h6>{t('conlusion')}</h6>
                            </div>
                            <div style={{ height: "15vw" }} />
                        </div>
                    </div>

                </div>
                <Sidebar />
            </div>
        </div>

    );
}

export default AboutUsPage