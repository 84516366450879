import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import i18n, { loadNamespaceForLanguage } from '../../i18n';
import vi from "../../assets/flags/vn.svg";
import en from "../../assets/flags/en.svg";
import { namespaces } from "../../const/variable.const"

const LanguageSwitcher = () => {
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || i18n.language || 'vi');

    const languageOptions = [
        { value: 'vi', label: 'VN', icon: vi },
        { value: 'en', label: 'EN', icon: en },
    ];

    const CustomOption = ({ data, ...props }) => (
        <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={data.icon} alt={data.label} style={{ width: 20, marginRight: 10 }} />
                {data.label}
            </div>
        </components.Option>
    );

    const CustomSingleValue = ({ data, ...props }) => (
        <components.SingleValue {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={data.icon} alt={data.label} style={{ width: 20, marginRight: 10 }} />
                {data.label}
            </div>
        </components.SingleValue>
    );

    const handleChangeLanguage = async (selectedOption) => {
        const newLang = selectedOption.value;

        // Update state before changing language
        setSelectedLanguage(newLang);

        // Load and cache translations for the new language
        await loadNamespaceForLanguage(newLang, namespaces);

        // Change language in i18n
        i18n.changeLanguage(newLang);

        // Optionally save current language to local storage
        localStorage.setItem('selectedLanguage', newLang);
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage && savedLanguage !== selectedLanguage) {
            setSelectedLanguage(savedLanguage);
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    return (
        <Select
            value={languageOptions.find(option => option.value === selectedLanguage)}
            onChange={handleChangeLanguage}
            options={languageOptions}
            isClearable={false}
            isSearchable={false}
            className="language-switcher"
            components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        />
    );
};

export default LanguageSwitcher;
