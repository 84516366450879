import React from 'react';
import PageHeader from '../components/global-components/page-header';
import PersonalTaxAuditDetails from '../components/tax-audit-components/personal/personal-tax-audit-details';
import { useTranslation } from 'react-i18next';

const PersonalTaxAuditDetailsPage = () => {
    const { t } = useTranslation('common');
    return (
        <div>
            <PageHeader headertitle={t('navbar.personalIncomeTax')}
            // subheader={t('navbar.personal')}
            />
            <PersonalTaxAuditDetails />
        </div>

    );
}

export default PersonalTaxAuditDetailsPage